import { Navigate, Route, Routes } from "react-router-dom";
import { ErrorBoundary } from "./components/error-boundary";
import { Nav } from "./components/nav";
import { DetailsScreen } from "./screens/details-screen";
import { NotFoundScreen } from "./screens/not-found-screen";
import { SelectionScreen } from "./screens/selection-screen";

function App() {
  return (
    <div className="min-h-full pb-16">
      <div className="bg-slate-600 border-b-2 border-gray-200 shadow-sm">
        <Nav />
      </div>
      <div className="mt-8">
        <ErrorBoundary>
          <AppRoutes />
        </ErrorBoundary>
      </div>
    </div>
  );
}

function AppRoutes() {
  return (
    <Routes>
      <Route path="/details/cert/:cert" element={<DetailsScreen />} />
      <Route path="/selection" element={<SelectionScreen />} />
      <Route path="/" element={<Navigate to="/selection" replace />} />
      <Route path="*" element={<NotFoundScreen />} />
    </Routes>
  );
}

export { App };
