interface IHeaderProps {
  heading: string;
}

function Header({ heading }: IHeaderProps) {
  return (
    <header>
      <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
        <h1 className="text-3xl font-bold leading-tight text-gray-800 mb-6">
          {heading}
        </h1>
      </div>
    </header>
  );
}

export { Header };
