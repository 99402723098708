import { Switch } from "@headlessui/react";
import { Link } from "react-router-dom";
import { FavoriteToggle } from "./favorite-toggle";

interface IFavoriteCardProps {
  cert: number;
}

function FavoriteCard({ cert }: IFavoriteCardProps) {
  return (
    <div className="bg-gray-50 shadow-md sm:rounded-lg mb-8">
      <Switch.Group as="div" className="px-4 py-5 sm:p-6">
        <Switch.Label
          as="h3"
          className="text-md font-medium text-indigo-500"
          passive
        >
          FDIC Certificate #{cert}
        </Switch.Label>
        <div className="mt-2 sm:flex sm:items-start sm:justify-between">
          <div className="max-w-xl text-sm text-gray-500">
            <Switch.Description>
              A unique NUMBER assigned by the FDIC used to identify institutions
              and for the issuance of insurance certificates.
            </Switch.Description>
            <div className="mt-3 text-sm">
              <Link
                to={`/details/cert/${cert}`}
                className="font-medium text-pink-600 hover:text-pink-500"
              >
                {" "}
                Learn more <span aria-hidden="true">&rarr;</span>
              </Link>
            </div>
          </div>
          <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
            <FavoriteToggle cert={cert} />
          </div>
        </div>
      </Switch.Group>
    </div>
  );
}

export { FavoriteCard };
