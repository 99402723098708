import NProgress from "nprogress";
import { useEffect, useState } from "react";
import { INotesLocalStorage } from "../models/local-storage-interfaces";
import { useLocalStorage } from "../utils/use-local-storage";

function useNotes(cert: number) {
  const [state, setState] = useLocalStorage<INotesLocalStorage>("notes", {});
  const [notes, setNotes] = useState<string>(state[cert] || "");

  // TODO: performance bottleneck - ideal solution is a back-end API
  useEffect(() => {
    NProgress.start();
    if (!notes) {
      delete state[cert];
      setState(state);
      NProgress.done();
      return;
    }

    const newNotes: INotesLocalStorage = {
      [cert]: notes,
    };
    const newState = Object.assign({}, state, newNotes);
    setState(newState);
    NProgress.done();
  }, [notes]); // eslint-disable-line react-hooks/exhaustive-deps

  return [notes, setNotes] as const;
}

export { useNotes };
