import { useState } from "react";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Divider } from "../components/divider";
import { Favorites } from "../components/favorites";
import { Header } from "../components/header";
import { SearchBox } from "../components/search-box";
import { SearchResults } from "../components/search-results";
import { useInstitutionName } from "../libs/data-access/use-institutions";
import { IFavoritesLocalStorage } from "../libs/models/local-storage-interfaces";
import { useLocalStorage } from "../libs/utils/use-local-storage";

function SelectionScreen() {
  const [institutionName, setInstitutionName] = useState<string>("");
  const { data: institutions, status } = useInstitutionName(institutionName);
  const [favorites] = useLocalStorage<IFavoritesLocalStorage>("favorites", {});
  const pages = [{ name: "Selection", to: "/", current: true }];

  function onSubmit(name: string): void {
    setInstitutionName(name);
  }

  return (
    <>
      <Breadcrumbs pages={pages} />
      <Header heading="Financial Institution Selection" />
      <main>
        <div className="max-w-2xl mx-auto sm:px-6 lg:px-8">
          <SearchBox institutionName={institutionName} onSubmit={onSubmit} />

          <div className="mt-8">
            {status === "loading" ? (
              <p>🚀 Loading...</p>
            ) : status === "success" && institutions.data.length ? (
              <>
                <Divider text="💰 Results" />
                <SearchResults institutions={institutions.data} />
              </>
            ) : status === "success" && !institutions.data.length ? (
              <>
                <Divider text="💰 Results" />
                <p> 💸 No institutions found.</p>
              </>
            ) : (
              <>
                <Divider text="💖 Favorites" />
                <Favorites favorites={favorites} />
              </>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export { SelectionScreen };
