import { useParams } from "react-router-dom";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Divider } from "../components/divider";
import { Header } from "../components/header";
import { InstitutionPanel } from "../components/institution-panel";
import { useInstitutionCert } from "../libs/data-access/use-institutions";

const DetailsScreen = () => {
  const params = useParams<{ cert: string }>();
  const { cert } = params;
  const { data: institutions, status } = useInstitutionCert(cert as string);
  const pages = [
    { name: "Selection", to: "/", current: false },
    { name: "Details", to: `/details/cert/${cert}`, current: true },
  ];

  return (
    <>
      <Breadcrumbs pages={pages} />
      <Header heading="Financial Institution Details" />
      <main>
        <div className="max-w-2xl mx-auto sm:px-6 lg:px-8">
          <div className="mt-8">
            {status === "loading" ? (
              <p>🚀 Loading...</p>
            ) : status === "success" && institutions.data.length ? (
              <>
                <Divider text="🔍 Inspect" />
                <InstitutionPanel details={institutions.data[0].data} />
              </>
            ) : status === "success" && !institutions.data.length ? (
              <>
                <Divider text="🔍 Details" />
                <p> 💸 No institution details found.</p>
              </>
            ) : null}
          </div>
        </div>
      </main>
    </>
  );
};

export { DetailsScreen };
