import NProgress from "nprogress";
import { useQuery } from "react-query";
import { BASE_API_URL } from "../../../config";
import {
  IInstitutionAutocompleteAPIResponse,
  IInstitutionsAPIResponse,
} from "../models/api-interfaces";
import { useDebounce } from "../utils/use-debounce";
import { IInstitutionAutocompleteData } from "../models/api-interfaces";

const MODEL = `institutions`;

async function getInstitutionAutocomplete(name: string) {
  const response = await fetch(
    `${BASE_API_URL}/${MODEL}?autocomplete=NAME:${encodeURIComponent(
      name
    )}&fields=NAME&limit=25`
  );
  const json = (await response.json()) as IInstitutionAutocompleteAPIResponse;

  if (response.ok) {
    return getUniqueNames(json.data);
  } else {
    return Promise.reject(json);
  }

  function getUniqueNames(results: IInstitutionAutocompleteData[]) {
    if (results.length) {
      const names = results.map((result) => {
        return result.data.NAME;
      });

      return names.filter(onlyUniqueNames);
    }

    function onlyUniqueNames(name: string, index: number, self: string[]) {
      return self.indexOf(name) === index;
    }
  }
}

async function getInstitutionByCert(cert: string) {
  NProgress.start();
  const response = await fetch(
    `${BASE_API_URL}/${MODEL}?filters=CERT:${cert}&limit=1`
  );
  const json = (await response.json()) as IInstitutionsAPIResponse;

  if (response.ok) {
    NProgress.done();
    return json;
  } else {
    NProgress.done();
    return Promise.reject(json);
  }
}

async function getInstitutionsByName(name: string) {
  NProgress.start();
  const response = await fetch(
    `${BASE_API_URL}/${MODEL}?filters=&limit=25&&search=NAME:${encodeURIComponent(
      name
    )}`
  );
  const json = (await response.json()) as IInstitutionsAPIResponse;

  if (response.ok) {
    NProgress.done();
    return json;
  } else {
    NProgress.done();
    return Promise.reject(json);
  }
}

function useInstitutionAutocomplete(institutionName: string) {
  const debouncedSearchTerm = useDebounce(institutionName, 500);

  return useQuery(
    ["autocomplete", institutionName],
    () => getInstitutionAutocomplete(debouncedSearchTerm),
    {
      enabled: !!debouncedSearchTerm && debouncedSearchTerm.length >= 3,
      staleTime: 5000 * 60,
    }
  );
}

function useInstitutionCert(institutionCert: string) {
  return useQuery(
    ["cert", institutionCert],
    () => getInstitutionByCert(institutionCert),
    {
      enabled: !!institutionCert,
    }
  );
}

function useInstitutionName(institutionName: string) {
  return useQuery(
    ["name", institutionName],
    () => getInstitutionsByName(institutionName),
    {
      enabled: !!institutionName,
    }
  );
}

export { useInstitutionAutocomplete, useInstitutionCert, useInstitutionName };
