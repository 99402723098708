import { SyntheticEvent } from "react";
import { useNotes } from "../libs/data-access/use-notes";

interface INotesProps {
  cert: number;
}

function Notes({ cert }: INotesProps) {
  const [notes, setNotes] = useNotes(cert);

  function handleSubmit(event: SyntheticEvent<HTMLFormElement>) {
    event.preventDefault();
    const form = event.currentTarget;
    const formElements = form.elements as typeof form.elements & {
      notes: { value: string };
    };
    setNotes(formElements.notes.value);
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <label
          htmlFor="notes"
          className="block text-sm font-medium text-gray-700 sr-only"
        >
          Add your notes
        </label>
        <div className="mt-1">
          <textarea
            rows={8}
            name="notes"
            id="notes"
            className="shadow-sm focus:ring-pink-500 focus:border-pink-500 block w-full sm:text-sm border-gray-300 rounded-md"
            defaultValue={notes}
            placeholder="Add your notes..."
          />
        </div>
        <div className="mt-2 flex justify-end">
          <button
            type="submit"
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-pink-600 hover:bg-pink-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            Save Notes
          </button>
        </div>
      </form>
    </div>
  );
}

export { Notes };
