import { IInstitution } from "../libs/models/api-interfaces";
import { InstitutionCard } from "./institution-card";
import { OpenStreetMap } from "./open-street-map";
import { Notes } from "./notes";

interface IInstitutionProps {
  details: Partial<IInstitution>;
}

function InstitutionPanel({ details }: IInstitutionProps) {
  const {
    ACTIVE,
    ADDRESS,
    CERT,
    CITY,
    COUNTY,
    ENDEFYMD,
    ESTYMD,
    EQ,
    INSDATE,
    LATITUDE,
    LONGITUDE,
    REGAGNT,
    STALP,
    ULTCERT,
    ZIP,
  } = details;

  return (
    <div>
      <div className="bg-gray-50 shadow-md overflow-hidden sm:rounded-lg">
        <ul className="divide-y divide-gray-200">
          <InstitutionCard details={details} />
        </ul>
        <OpenStreetMap
          latitude={Number(LATITUDE)}
          longitude={Number(LONGITUDE)}
        />
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Primary Regulator
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{REGAGNT}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Ultimate Certificate
              </dt>
              <dd className="mt-1 text-sm text-gray-900">#{ULTCERT}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Address</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {ADDRESS} <br />
                {CITY}, {STALP} {ZIP} <br />
                {COUNTY} County
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Total Equity
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                ${Number(EQ).toLocaleString("en-US")}
              </dd>
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">About</dt>

              {ACTIVE === 1 ? (
                <dd className="mt-1 text-sm text-gray-900">
                  The institution began operations in {ESTYMD} and has been FDIC
                  insured Since {INSDATE}.
                </dd>
              ) : (
                <dd className="mt-1 text-sm text-gray-900">
                  The institution began operations in {ESTYMD} and closed as of{" "}
                  {ENDEFYMD}. Insured until closed.
                </dd>
              )}
            </div>
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Notes</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <Notes cert={Number(CERT)} />
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}

export { InstitutionPanel };
