import { NavLink } from "react-router-dom";

function Nav() {
  return (
    <div className="max-w-2xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="flex justify-between h-16">
        <div className="flex">
          <div className="flex items-center mr-4">
            <NavLink to="/">
              <h1 className="text-3xl">🏦</h1>
            </NavLink>
          </div>
          <NavLink
            to="/selection"
            className="border-pink-500 text-gray-100 inline-flex items-center px-1 pt-1 border-b-4 text-md font-medium"
            aria-current="page"
          >
            Dashboard
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export { Nav };
