import { Combobox } from "@headlessui/react";
import { CheckIcon, SearchIcon, SelectorIcon } from "@heroicons/react/solid";
import { ChangeEvent, useState } from "react";
import { useInstitutionAutocomplete } from "../libs/data-access/use-institutions";
import { classNames } from "../libs/utils/tailwindcss-helpers";

interface ISearchBoxProps {
  institutionName: string;
  onSubmit: (name: string) => void;
}

function SearchBox({ institutionName = "", onSubmit }: ISearchBoxProps) {
  const [query, setQuery] = useState<string>(institutionName);
  const [selectedInstitutionName, setSelectedInstitutionName] =
    useState<string>("");
  const { data: institutionNames, status: autocompleteStatus } =
    useInstitutionAutocomplete(query);

  function handleChange(name: string) {
    if (!name) {
      name = "";
    }

    setQuery(name);
    setSelectedInstitutionName(name);
    onSubmit(name);
  }

  return (
    <Combobox
      as="div"
      value={selectedInstitutionName}
      onChange={handleChange}
      nullable
    >
      <Combobox.Label className="block text-sm font-medium text-gray-700">
        Search Institutions By Name
      </Combobox.Label>
      <div className="relative mt-1">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <Combobox.Input
          type="text"
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-10 pr-10 shadow-sm focus:border-pink-500 focus:outline-none focus:ring-1 focus:ring-pink-500 sm:text-sm"
          placeholder="e.g. U.S. Bank National Association"
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setQuery(event.target.value)
          }
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {autocompleteStatus === "success" && query.length ? (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            <Combobox.Option
              value={query}
              className={({ active }) =>
                classNames(
                  "relative cursor-default select-none py-2 pl-3 pr-9",
                  active ? "bg-gray-200 text-gray-600" : "text-gray-900"
                )
              }
            >
              <span className={classNames("block truncate", "font-normal")}>
                {query}
              </span>
            </Combobox.Option>
            {institutionNames?.map((name) => (
              <Combobox.Option
                key={name}
                value={name}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-pink-600 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames("block truncate", "font-semibold")}
                    >
                      {name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-pink-600"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        ) : null}
      </div>
      <p className="mt-2 text-sm text-gray-500" id="search-description">
        {`🤖 Fin "The Fintech Autocomplete Bot" is at your service.`}
      </p>
    </Combobox>
  );
}

export { SearchBox };
