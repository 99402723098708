import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

interface IOpenStreetMapProps {
  latitude: number;
  longitude: number;
}

function OpenStreetMap({
  latitude = 82.8628,
  longitude = 135.0,
}: IOpenStreetMapProps) {
  return (
    <div>
      <MapContainer
        center={[latitude as number, longitude as number]}
        zoom={13}
        scrollWheelZoom={false}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <Marker position={[latitude as number, longitude as number]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    </div>
  );
}

export { OpenStreetMap };
