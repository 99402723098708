import { LibraryIcon, LocationMarkerIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { IInstitution } from "../libs/models/api-interfaces";
import { FavoriteToggle } from "./favorite-toggle";

interface IInstitutionProps {
  details: Partial<IInstitution>;
}

function InstitutionCard({ details }: IInstitutionProps) {
  const { ACTIVE, CERT, CITY, NAME, STALP } = details;

  return (
    <li key={CERT}>
      <Link
        to={`/details/cert/${CERT}`}
        className="block bg-gray-50 hover:bg-gray-100"
      >
        <div className="px-4 py-4 sm:px-6">
          <div className="flex items-center justify-between">
            <p className="text-md font-medium text-indigo-500">{NAME}</p>
            <div className="ml-2 flex-shrink-0 flex">
              {ACTIVE === 1 ? (
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800">
                  Active
                </p>
              ) : (
                <p className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-red-100 text-red-800">
                  Inactive
                </p>
              )}
            </div>
          </div>
          <div className="mt-2 sm:flex sm:justify-between">
            <div className="sm:flex">
              <p className="flex items-center text-sm text-gray-500">
                <LibraryIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                FDIC #{CERT}
              </p>
              <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
                <LocationMarkerIcon
                  className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                {CITY}, {STALP}
              </p>
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
              <FavoriteToggle cert={Number(CERT)} />
            </div>
          </div>
        </div>
      </Link>
    </li>
  );
}

export { InstitutionCard };
