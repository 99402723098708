import NProgress from "nprogress";
import { useEffect } from "react";
import { useLocalStorage } from "../utils/use-local-storage";
import { useToggle } from "../utils/use-toggle";
import { IFavoritesLocalStorage } from "./../models/local-storage-interfaces";

function useFavorites(cert: number) {
  const [state, setState] = useLocalStorage<IFavoritesLocalStorage>(
    "favorites",
    {}
  );
  const [isFavorited, setIsFavorited] = useToggle(state[cert] || false);

  // TODO: performance bottleneck - ideal solution is a back-end API
  useEffect(() => {
    NProgress.start();
    const newFavorite: IFavoritesLocalStorage = { [cert]: isFavorited };
    const currentFavorites = window.localStorage.getItem("favorites");
    const currentState: IFavoritesLocalStorage = JSON.parse(
      currentFavorites as string
    );
    const newState = Object.assign({}, currentState, newFavorite);

    if (!isFavorited) {
      delete newState[cert];
    }

    setState(newState);
    NProgress.done();
  }, [isFavorited]); // eslint-disable-line react-hooks/exhaustive-deps

  return [isFavorited, setIsFavorited] as const;
}

export { useFavorites };
