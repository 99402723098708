import { IInstitutionData } from "../libs/models/api-interfaces";
import { InstitutionCard } from "./institution-card";

interface ISearchResultsProps {
  institutions: IInstitutionData[];
}

function SearchResults({ institutions }: ISearchResultsProps) {
  return (
    <>
      {!institutions.length ? (
        <h1>No Institutions Found.</h1>
      ) : (
        <div className="bg-white shadow-md overflow-hidden sm:rounded-md">
          <ul className="divide-y divide-gray-200">
            {institutions.map((institution) => (
              <InstitutionCard
                details={institution.data}
                key={institution.data.CERT}
              />
            ))}
          </ul>
        </div>
      )}
    </>
  );
}

export { SearchResults };
