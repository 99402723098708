import { Switch } from "@headlessui/react";
import { HeartIcon } from "@heroicons/react/solid";
import { useFavorites } from "../libs/data-access/use-favorites";
import { classNames } from "../libs/utils/tailwindcss-helpers";

interface IFavoriteToggleProps {
  cert: number;
}

function FavoriteToggle({ cert }: IFavoriteToggleProps) {
  const [isFavorited, setIsFavorited] = useFavorites(cert);

  return (
    <Switch.Group as="div" className="flex items-center">
      <HeartIcon
        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
        aria-hidden="true"
      />
      <Switch.Label as="span" className="mr-3 text-gray-500">
        <p className="text-sm">Favorite</p>
      </Switch.Label>
      <Switch
        checked={isFavorited}
        onChange={setIsFavorited}
        className={classNames(
          isFavorited ? "bg-pink-600" : "bg-gray-200",
          "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
        )}
      >
        <span
          aria-hidden="true"
          className={classNames(
            isFavorited ? "translate-x-5" : "translate-x-0",
            "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
          )}
        />
      </Switch>
    </Switch.Group>
  );
}

export { FavoriteToggle };
