import { Component, ErrorInfo } from "react";
import { Link, Navigate } from "react-router-dom";
import PleaseDisperse from "../assets/please-disperse.gif";
import { Header } from "./header";

class ErrorBoundary extends Component {
  state = { hasError: false, redirect: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, info: ErrorInfo): void {
    console.error("Error", error, info);
  }

  componentDidUpdate() {
    if (this.state.hasError) {
      setTimeout(() => this.setState({ redirect: true }), 5000);
    }
  }
  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    } else if (this.state.hasError) {
      return (
        <>
          <Header heading="Everything is fine..." />
          <main>
            <div className="max-w-2xl mx-auto sm:px-6 lg:px-8">
              <img
                src={PleaseDisperse}
                alt="Nothing to see here. Please Disperse."
              />
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-pink-600 hover:text-pink-500"
                >
                  Click here to go back home{" "}
                  <span aria-hidden="true"> &rarr;</span>
                </Link>{" "}
              </div>
            </div>
          </main>
        </>
      );
    }

    return this.props.children;
  }
}

export { ErrorBoundary };
