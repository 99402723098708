import { Link } from "react-router-dom";
import GottaGoFast from "../assets/gotta-go-fast.gif";
import { Breadcrumbs } from "../components/breadcrumbs";
import { Header } from "../components/header";

function NotFoundScreen() {
  const pages = [{ name: "404", to: `/not-found`, current: true }];

  return (
    <>
      <Breadcrumbs pages={pages} />
      <Header heading="Oops, page not found." />
      <main>
        <div className="max-w-2xl mx-auto sm:px-6 lg:px-8">
          <img src={GottaGoFast} alt="Sonic gotta go fast" />

          <div className="mt-6">
            <Link
              to="/selection"
              className="text-base font-medium text-pink-600 hover:text-pink-500"
            >
              Go back home fast<span aria-hidden="true"> &rarr;</span>
            </Link>
          </div>
        </div>
      </main>
    </>
  );
}

export { NotFoundScreen };
