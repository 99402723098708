import { IFavoritesLocalStorage } from "../libs/models/local-storage-interfaces";
import { FavoriteCard } from "./favorite-card";

interface IFavoritesProps {
  favorites: IFavoritesLocalStorage;
}

function Favorites({ favorites }: IFavoritesProps) {
  return (
    <>
      {Object.keys(favorites).length ? (
        Object.keys(favorites).map((cert) => (
          <FavoriteCard cert={Number(cert)} key={cert} />
        ))
      ) : (
        <p>♡ No favorites found.</p>
      )}
    </>
  );
}

export { Favorites };
